.current {
  color: green;
}

h1,h2,h3,h4,h5,h6{
    font-family:"PT Sans Narrow",sans-serif
}
h1{
    font-size:32px;
    font-size:2rem;
    line-height:0.8125;
    margin-bottom:26px;
    margin-bottom:1.625rem
}

.object-fit_cover { 
    object-fit: cover 
}


/* styles.css | https://localhost:4000/assets/css/styles.css */

.image-wrap img {
  /* height: auto; */
  height: 300px;
  /* object-position: top; */
}

@media only screen and (min-width: 86.375em) {
  .image-credit {
    /* width: 70.83333%; */
    width: 98%;
  }
}




/* ========================================================================== ARCHIVE ========================================================================== */
.archive { margin-bottom: 2em; }
@media (min-width: 64em) { .archive { float: right; width: calc(100% - 200px); padding-right: 200px; } }
@media (min-width: 80em) { .archive { float: left; width: calc(100% - 20px); padding-right: 300px; } }

.archive__subtitle { margin: 1.414em 0 0; padding-bottom: 0.5em; font-size: 1em; color: #000; border-bottom: 1px solid #bfbfbf; }
.archive__subtitle + .list__item .archive__item-title { margin-top: 0.5em; }

.archive__item-title { margin-bottom: 0.25em; font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif; line-height: initial; overflow: hidden; text-overflow: ellipsis; }
.archive__item-title a { color: inherit; }
.archive__item-title a + a { opacity: 0.5; }

/* remove border*/
.page__content .archive__item-title { margin-top: 1em; border-bottom: none; }

.archive__item-excerpt { margin-top: 0; font-size: 0.75em; }
.archive__item-excerpt + p { text-indent: 0; }

.archive__item-teaser { aspect-ratio: 16 / 9; position: relative; border-radius: 4px; /*border: .5px solid;*/ overflow: hidden; }
.archive__item-teaser img { object-fit: fill;
height: 100%; width: auto; vertical-align: middle; text-align: center;
}

.archive__item-caption { position: absolute; bottom: 0; right: 0; margin: 0 auto; padding: 2px 5px; color: #fff; font-family: Georgia, Times, serif; font-size: 0.625em; background: #000; text-align: right; z-index: 5; opacity: 0.5; border-radius: 4px 0 0 0; }
@media (min-width: 64em) { .archive__item-caption { padding: 5px 10px; } }
.archive__item-caption a { color: #fff; text-decoration: none; }

/* List view ========================================================================== */
.list__item .page__meta, .list__item .comment__date { margin: 0 0 4px; font-size: 0.6em; }

/* Grid view ========================================================================== */
.archive .grid__wrapper { /* extend grid elements to the right */ }
@media (min-width: 64em) { .archive .grid__wrapper { margin-right: -200px; } }
@media (min-width: 80em) { .archive .grid__wrapper { margin-right: -300px; } }

.grid__item { margin-bottom: 2em; }
@media (min-width: 37.5em) { .grid__item { float: left; width: 48.9795918367%; }
  .grid__item:nth-child(2n + 1) { clear: both; margin-left: 0; }
  .grid__item:nth-child(2n + 2) { clear: none; margin-left: 2.0408163265%; } }
@media (min-width: 48em) { .grid__item { margin-left: 0; /* override margin*/ margin-right: 0; /* override margin*/ width: 23.7288135593%; }
  .grid__item:nth-child(2n + 1) { clear: none; }
  .grid__item:nth-child(4n + 1) { clear: both; }
  .grid__item:nth-child(4n + 2) { clear: none; margin-left: 1.6949152542%; }
  .grid__item:nth-child(4n + 3) { clear: none; margin-left: 1.6949152542%; }
  .grid__item:nth-child(4n + 4) { clear: none; margin-left: 1.6949152542%; } }
.grid__item .page__meta, .grid__item .comment__date { margin: 0 0 4px; font-size: 0.6em; }
.grid__item .archive__item-title { margin-top: 0.5em; font-size: 1em; }
.grid__item .archive__item-excerpt { display: none; }
@media (min-width: 48em) { .grid__item .archive__item-excerpt { display: block; font-size: 0.75em; } }
@media (min-width: 37.5em) { .grid__item .archive__item-teaser { max-height: 200px; } }
@media (min-width: 48em) { .grid__item .archive__item-teaser { max-height: 120px; } }



@media only screen {#main .post,#main .page{display:inline;float:left;width:55%}}


p + p {
    text-indent: 0rem;
    margin-top: -1rem;
}

table strong{
font-family: "PT Sans Narrow",sans-serif;
font-size: large;
line-height:2.5;
}

table a{
text-decoration: underline;
}

li p {
    margin: 0;
}

/* Print Only */
@media print {
  .noprint {display:none !important;}
  .noshow {display:block !important;}
  }